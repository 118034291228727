import type { TranslationProxy } from 'intl-schematic'

import { createTranslator } from 'intl-schematic'
import { useHydrationStore, useContextData, useNuxtApp } from '#imports'
import { toRef } from 'vue'

interface State {
  locale: string
  fallbackLocale: string
  translator?: TranslationProxy<any, any>
}

export function useLocalization () {
  const nuxtApp = useNuxtApp()
  const state = useHydrationStore<State>('config-localization', {
    locale: 'en',
    fallbackLocale: 'en'
  })
  const messages = useContextData<Record<string, string>>('localization-messages', {})

  const getDocument = () => messages.value
  const getLocale = () => new Intl.Locale(state.value.locale)

  async function setLocale (lang: string) {
    if (lang !== state.value.locale || Object.entries(messages.value).length === 0) {
      const messages = await loadDynamicMessages(lang)

      setMessages(messages)
    }

    state.value.locale = lang

    nuxtApp.hooks.callHook('localization:locale-changed', lang)
  }

  async function loadDynamicMessages (lang: string) {
    const messages = await import(`./messages/${lang}.json?raw`)

    return JSON.parse(messages.default)
  }

  function setMessages (messagesList: Record<string, any>) {
    messages.value = messagesList
  }

  function getTranslator (): TranslationProxy<any, any> {
    return createTranslator(getDocument, getLocale)
  }

  function getStandardizedLocale (): string {
    return state.value.locale === 'kz' ? 'kk' : state.value.locale
  }

  return {
    setLocale,
    setMessages,
    getTranslator,
    loadDynamicMessages,
    getStandardizedLocale,

    locale: toRef(state.value, 'locale'),
    fallbackLocale: toRef(state.value, 'fallbackLocale'),
    messages: messages.value,
    t: getTranslator()
  }
}
